import {ManagementProps} from "./propTypes";
import {useContext, useEffect, useRef, useState} from "react";
import {ComponentLineDTO} from "../../api/types";
import {runInAction} from "mobx";
import {observer} from "mobx-react-lite";
import RoomStore from "../store/RoomStore";
import Slider from "rc-slider";
import {SlideValue, Thumb} from "../controls/Common";
import ContextMenu from "./menu/ContextMenu";
import Timers from "./menu/Timer";
import Spinner from "./info/Spinner";
import Offline from "./info/Offline";
import {clearTimeout, setTimeout} from "worker-timers";


function TemperatureController(props: ManagementProps) {

    const [cmpState, setCmpState] = useState<ComponentLineDTO>(props.line);
    const roomStore = useContext(RoomStore);
    const [openTimer, setOpenTimer] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState<string>("0");
    const [processing, setProcessing] = useState(false);
    const [offline, setOfflane] = useState(false);
    const processingRef = useRef(false);
    const [onoff, setOnOff] = useState("0");
    const [timeout, setTimeoutRef] = useState<number | null>(null);


    function onClick(topicName: string, value: number | string | number[], submit: boolean = true) {
        runInAction(() => {
            let updatedIndex = cmpState.topics.findIndex((p) => p.topicName === topicName);
            if (updatedIndex <= 0) {
                let newValue: string = "" + value;
                cmpState.topics.push({topicName, value: newValue})
            } else {
                let keyValue = cmpState.topics[updatedIndex];
                keyValue.value = "" + value;
            }
        })
        if (topicName !== "onoff") {
            setCmpState(cmpState);
        }
        if (submit) {
            setOfflane(false);
            setProcessing(true);
            submitData(topicName, "" + value);
            if (timeout) {
                clearTimeout(timeout);
                setTimeoutRef(null);
            }
            let timeoutValue = setTimeout(() => {
                if (processingRef.current) {
                    setProcessing(false);
                    setOfflane(true);
                }
            }, 10000)
            setTimeoutRef(timeoutValue);
        }
    }

    function changeView(menuNumber: string) {
        setSelectedMenu(menuNumber);
    }

    function isMainSelected(): boolean {
        return selectedMenu === "0";
    }

    function submitData(topicName: string, s: string) {
        roomStore.updateManagementData(cmpState.id, {...cmpState, topics: [{topicName: topicName, value: s}]})
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        setCmpState(props.line);
        setProcessing(false);
        let outputValue = props.line.topics.find((p) => p.topicName === "onoff");
        if (outputValue && outputValue.value) {
            setOnOff(outputValue.value);
        } else {
            setOnOff("0");
        }
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
    }, [props]);

    useEffect(() => {
        processingRef.current = processing;
    }, [processing]);

    function renderIcons(): boolean {
        let keyValue = cmpState.topics.find((t) => t.topicName === "TypeTH");
        if (keyValue) {
            if (keyValue.value && (keyValue.value === "3" || keyValue.value === "4")) {
                return true
            }
        }
        return false;
    }

    function renderRange(): boolean {
        let keyValue = cmpState.topics.find((t) => t.topicName === "TypeTH");
        if (keyValue) {
            if (keyValue.value && (keyValue.value === "7" || keyValue.value === "8" || keyValue.value === "9" || keyValue.value === "10")) {
                return false
            }
        }
        return true;
    }

    function getColor(): string {
        let find = cmpState.topics.find(key => key.topicName === 'headcoolmodefb');
        if (find) {
            if (find.value === "1") {
                return "blue";
            } else if (find.value === "2") {
                return "orange";
            }
        }
        return "white";
    }

    function getBackGroundColor(): string {
        let find = cmpState.topics.find(key => key.topicName === 'headcoolmodefb');
        if (find) {
            if (find.value === "3") {
                return "#50C878"
            } else if (find.value === "4") {
                return "lightgray";
            }
        }
        return "";
    }

    function getOnOffButtonColor() {
        return onoff === "1" ? "" : 'grey';
    }


    function getTemperature() {
        let value = cmpState.topics.find(key => key.topicName === 'data')?.value;
        if (value && value !== "") {
            let keyValue = cmpState.topics.find((t) => t.topicName === "TypeTH");
            if (keyValue) {
                if (keyValue.value === "9" || keyValue.value === "10" || keyValue.value === "7" || keyValue.value === "8") {
                    return (+value).toFixed(0);
                } else {
                    return (+value).toFixed(1);
                }
            }
            return (+value).toFixed(1);
        }
        return "";
    }

    function getSliderValue() {
        let value = cmpState.topics.find(key => key.topicName === 'Set0')?.value;
        if (value && value !== "") {
            return +value;
        }
        return 0;
    }

    function renderMessaruments() {
        let keyValue = cmpState.topics.find((t) => t.topicName === "TypeTH");
        if (keyValue) {
            if (keyValue.value) {
                if (keyValue.value === "9" || keyValue.value === "10") {
                    return "ppm"
                } else if (keyValue.value === "7" || keyValue.value === "8") {
                    return "%"
                } else {
                    return "℃";
                }
            }
        }
    }

    return (
        <div className="col col-4 card-item">
            <div className="card">
                <div className="card__header">
                    <h3>{cmpState.name}</h3>

                    {processing &&
                        <Spinner/>
                    }

                    {offline &&
                        <Offline/>
                    }

                    <ContextMenu onMenuClick={changeView}/>
                </div>


                <div
                    className={"card__body card__body-with-icon d-flex align-items-center justify-content-end flex-wrap" + (openTimer ? " is-open-select " : "")}>
                    {isMainSelected() &&
                        <>
                            <div className="card__body--left">
                                {renderIcons() &&
                                    <div className="card__body-radio mb12">
                                        <div className="row">
                                            <div className="col col-4">
                                                <div className="item">
                                                    <input type="radio" id={cmpState.id + "_tc_7"}
                                                           name={cmpState.id + "temperature_control"}

                                                           checked={cmpState.topics.find(key => key.topicName === 'mode')?.value === "0"}
                                                           onChange={() => onClick("mode", 0)}/>
                                                    <label htmlFor={cmpState.id + "_tc_7"}
                                                           className="d-flex align-items-center justify-content-center">
                                                        <i className="icon-cold"></i>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col col-4">
                                                <div className="item">
                                                    <input type="radio" id={cmpState.id + "_tc_8"}
                                                           name={cmpState.id + "temperature_control"}
                                                           checked={cmpState.topics.find(key => key.topicName === 'mode')?.value === "1"}
                                                           onChange={() => onClick("mode", 1)}/>
                                                    <label htmlFor={cmpState.id + "_tc_8"}
                                                           className="d-flex align-items-center justify-content-center">
                                                        <i className="icon-warm"></i>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col col-4">
                                                <div className="item">
                                                    <input type="radio" id={cmpState.id + "_tc_9"}
                                                           name={cmpState.id + "temperature_control"}
                                                           checked={cmpState.topics.find(key => key.topicName === 'mode')?.value === "2"}
                                                           onChange={() => onClick("mode", 2)}/>
                                                    <label htmlFor={cmpState.id + "_tc_9"}
                                                           className="d-flex align-items-center justify-content-center">
                                                        <i className="icon-hot"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {renderRange() &&
                                    <div className="range-slider">
                                        <div className="slider">

                                            <Slider
                                                value={getSliderValue()}
                                                min={10}
                                                max={40}
                                                onChange={(event) => onClick("Set0", event, false)}
                                                onAfterChange={(event) => onClick("Set0", event)}
                                                handleRender={renderProps => {
                                                    return (
                                                        <div {...renderProps.props}>
                                                            <Thumb
                                                                className="noUi-handle noUi-handle-lower">
                                                                <div className="noUi-touch-area"></div>
                                                                <SlideValue
                                                                    className="noUi-tooltip"> {cmpState.topics.find(key => key.topicName === 'Set0')?.value}&#8451;
                                                                </SlideValue>
                                                            </Thumb>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card__body--right">
                                <button
                                    className={"card__body-btn d-flex align-items-center justify-content-center flex-wrap " + getColor()}
                                    onClick={() => onClick("onoff", onoff === "1" ? "0" : "1")}>
                                    <b>{getTemperature() + renderMessaruments()}</b>
                                    <div className="bar"
                                         style={{background: getOnOffButtonColor()}}></div>
                                </button>
                            </div>
                        </>
                    }
                    {!isMainSelected() &&
                        <Timers type={4} cmp={cmpState.id} abbr={cmpState.abbreviation}
                                onOpenTimer={setOpenTimer}
                                submitValue={roomStore.updateTimerValue} getTimerData={roomStore.getTimerValues}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default observer
(TemperatureController);