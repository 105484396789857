import logo from "../../img/logo.png"
import {Link} from "react-router-dom";
import React from "react";

function Logo() {
    return (
        <div className="header__logo">
            <Link to="/">
                <img src={logo} alt="Rex"/>
            </Link>
        </div>
);
}

export default Logo;