import AuthToken from "./AuthToken";
import {LoginResponse} from "../../api/types";


export class SessionStore {
    static readonly CREDENTIALS_STORAGE_KEY = 'rex/credentials';

    readToken(): AuthToken | null {
        const tokenData = localStorage.getItem(
            SessionStore.CREDENTIALS_STORAGE_KEY
        );

        if (tokenData) {
            return new AuthToken(
                JSON.parse(tokenData) as unknown as LoginResponse
            )
        }
        return null;
    }

    writeToken(authToken: LoginResponse|null) {
        if (authToken) {
            let s = JSON.stringify({
                jwtToken: authToken.jwtToken,
                expireDate: authToken.expireDate,
                refreshToken: authToken.refreshToken,
                refreshTokenExpireDate: authToken.refreshTokenExpireDate,
            });
            localStorage.setItem(SessionStore.CREDENTIALS_STORAGE_KEY, s);
        } else {
            this.clearToken();
        }
    }

    clearToken() {
        localStorage.removeItem(SessionStore.CREDENTIALS_STORAGE_KEY);
    }
}