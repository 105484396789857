import {observer} from "mobx-react-lite";
import DeviceContainer from "../controls/DeviceContainer";
import React, {useContext} from "react";
import RoomStore from "../store/RoomStore";

function DeviceList() {
    const roomStore = useContext(RoomStore);

    return (
        <div className="server__tables">
            {roomStore.clientDeviceList.map(info =>
                (<DeviceContainer {...info} key={info.id}/>)
            )
            }
        </div>
    )
}

export default observer(DeviceList);

