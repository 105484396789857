import {ManagementProps, minutes} from "./propTypes";
import {useContext, useEffect, useRef, useState} from "react";
import {ComponentLineDTO} from "../../api/types";
import {runInAction} from "mobx";
import {observer} from "mobx-react-lite";
import RoomStore from "../store/RoomStore";
import ContextMenu from "./menu/ContextMenu";
import Timers from "./menu/Timer";
import Spinner from "./info/Spinner";
import Offline from "./info/Offline";
import {setTimeout, clearTimeout} from 'worker-timers';


function Button(props: ManagementProps) {

    const [cmpState, setCmpState] = useState<ComponentLineDTO>(props.line);
    const [open, setOpen] = useState(false);
    const [openTimer, setOpenTimer] = useState(false);
    const roomStore = useContext(RoomStore);
    const [selectedMenu, setSelectedMenu] = useState<string>("0");
    const [processing, setProcessing] = useState(false);
    const [offline, setOfflane] = useState(false);
    const processingRef = useRef(false);
    const [timeout, setTimeoutRef] = useState<number | null>(null);
    const [onoff, setOnOff] = useState("0");

    useEffect(() => {
        setCmpState(props.line);
        setProcessing(false);
        let outputValue = props.line.topics.find((p) => p.topicName === "onoff");
        if (outputValue && outputValue.value) {
            setOnOff(outputValue.value);
        } else {
            setOnOff("0");
        }
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
    }, [props]);

    useEffect(() => {
        processingRef.current = processing;
    }, [processing]);

    function changeView(menuNumber: string) {
        setSelectedMenu(menuNumber);
    }

    function isMainSelected(): boolean {
        return selectedMenu === "0";
    }

    function onClick(topicName: string, value: string) {
        runInAction(() => {
            let updatedIndex = cmpState.topics.findIndex((p) => p.topicName === topicName);
            let keyValue = cmpState.topics[updatedIndex];
            keyValue.value = value;
            if (topicName === "ga1mode") {
                setSelectedMode(value);
            }
        })
        // cmpState.topics.splice(updatedIndex, 1, device)
        if (topicName !== "onoff") {
            setCmpState(cmpState);
        }
        setOfflane(false);
        setProcessing(true);
        roomStore.updateManagementData(cmpState.id, {...cmpState, topics: [{topicName: topicName, value: value}]})
            .catch((error) => console.log(error));
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
        let timeoutValue = setTimeout(() => {
            if (processingRef.current) {
                setProcessing(false);
                setOfflane(true);
                setTimeoutRef(null);
            }
        }, 10000);
        setTimeoutRef(timeoutValue);
    }

    const [selectedMode, setSelectedMode] = useState(cmpState.topics.find(key => key.topicName === "ga1mode")?.value);

    return (
        <div className="col col-4 card-item">
            <div className="card">
                <div className="card__header">
                    <h3>{cmpState.name}</h3>
                    {processing &&
                        <Spinner/>
                    }
                    {offline &&
                        <Offline/>
                    }
                    <ContextMenu onMenuClick={changeView}/>
                </div>


                <div
                    className={"card__body card__body-with-icon d-flex align-items-center justify-content-end flex-wrap"+ (open || openTimer ? " is-open-select " : "")}>
                    {isMainSelected() &&
                        <>
                            <div className="card__body--left">

                                <div className={"selectWrapper mb12" + (open ? " is-active" : "")}
                                     onClick={() => setOpen(!open)}>
                                    <div className="selectCustom h49 js-selectCustom">
                                        <div
                                            className="selectCustom-trigger"
                                        >{minutes.find(key => key.id === selectedMode)?.value}</div>
                                        <div className="selectCustom-options">
                                            {minutes.map((value) =>
                                                <div className="selectCustom-option" data-value={value.id}
                                                     key={value.id}
                                                     onClick={() => onClick("ga1mode", value.id)}>{value.value}</div>
                                            )}

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="card__body--right">
                                <button
                                    className={"card__body-btn d-flex align-items-center justify-content-center" + (onoff === "1" ? "  is-active" : "")}
                                    onClick={() => onClick("onoff", onoff === "1" ? "0" : "1")}
                                >
                                    < i className="icon-wifi"></i>
                                </button>
                            </div>
                        </>
                    }
                    {!isMainSelected() &&
                        <Timers type={2} cmp={cmpState.id} abbr={cmpState.abbreviation}
                                onOpenTimer={setOpenTimer}
                                submitValue={roomStore.updateTimerValue} getTimerData={roomStore.getTimerValues}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default observer
(Button);