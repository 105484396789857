import {ComponentLineDTO} from "../../api/types";

export interface ManagementProps {
    line: ComponentLineDTO;
}

export interface DropDownOption {
    id: string;
    value: string;
}

export const minutes: DropDownOption[] = [
    {
        id: "8",
        value: "3 min"
    },
    {
        id: "9",
        value: "5 min"
    },
    {
        id: "10",
        value: "10 min"
    }
]

export const colors: DropDownOption[] = [
    {
        id: "0",
        value: "Красный"
    },
    {
        id: "1",
        value: "Зеленый"
    },
    {
        id: "2",
        value: "Синий"
    },
    {
        id: "3",
        value: "Пурпурный"
    },
    {
        id: "4",
        value: "Голубой"
    },
    {
        id: "5",
        value: "Желтый"
    },
    {
        id: "6",
        value: "Белый"
    },
    {
        id: "7",
        value: "Теплый Белый"
    },
    {
        id: "8",
        value: "Теплый"
    },
    {
        id: "9",
        value: "RGB"
    },
    {
        id: "10",
        value: "Random"
    }]

export const modes: DropDownOption[] = [
    {
        id: "0",
        value: "OFF"
    },
    {
        id: "1",
        value: "Оранжевый"
    },
    {
        id: "2",
        value: "Белый"
    },
    {
        id: "3",
        value: "Конфети"
    },
    {
        id: "4",
        value: "Циклон"
    },
    {
        id: "5",
        value: "Змейка"
    },
    {
        id: "6",
        value: "Пацифика"
    },
    {
        id: "7",
        value: "Огонь"
    },
    {
        id: "8",
        value: "Прайд"
    },
    {
        id: "9",
        value: "AUX"
    }
]

export const modesW: DropDownOption[] = [
    {
        id: "0",
        value: "OFF"
    },
    {
        id: "1",
        value: "Оранжевый"
    },
    {
        id: "2",
        value: "Белый"
    },
    {
        id: "3",
        value: "Конфети"
    },
    {
        id: "4",
        value: "Циклон"
    },
    {
        id: "5",
        value: "Змейка"
    },
    {
        id: "6",
        value: "Пацифика"
    },
    {
        id: "7",
        value: "Огонь"
    },
    {
        id: "8",
        value: "Прайд"
    },
    {
        id: "9",
        value: "AUX1"
    },
    {
        id: "10",
        value: "AUX2"
    },
    {
        id: "11",
        value: "AUX2"
    },
    {
        id: "12",
        value: "AUX3"
    },
    {
        id: "13",
        value: "AUX4"
    },
    {
        id: "14",
        value: "AUX5"
    },
    {
        id: "15",
        value: "AUX6"
    },
    {
        id: "16",
        value: "AUX7"
    },
    {
        id: "17",
        value: "AUX8"
    },
    {
        id: "18",
        value: "AUX9"
    }
]

export const buttonModes = [
    {
        id: "0",
        value: ""
    },
    {
        id:"1",
        value: "click"
    },
    {
        id: "2",
        value: "turnON"
    },
    {
        id: "3",
        value: "turnOFF"
    },
    {
        id: "4",
        value: "Dimming"
    },
    {
        id: "5",
        value: "longClick"
    },
    {
        id: "6",
        value: "gerconONOFF"
    },
    {
        id: "7",
        value: "gerconOFFON"
    },
    {
        id: "8",
        value: "MS1minute"
    },
    {
        id: "9",
        value: "MS5minute"
    },
    {
        id: "10",
        value: "MS10minute"
    },
    {
        id: "11",
        value: "MS15minute"
    },
    {
        id: "12",
        value: "LONGturnON"
    },
    {
        id: "13",
        value: "LONGturnOFF"
    },
    {
        id: "14",
        value: "DoubleClickON"
    },
    {
        id: "15",
        value: "DoubleClickOFF"
    }
]
export const typeTH = [
    {
        id:"1",
        value: "Heat"
    },
    {
        id: "2",
        value: "Heat+2"
    },
    {
        id: "3",
        value: "Auto(heat/cool)"
    },
    {
        id: "4",
        value: "Auto(heat+2/cool+2)"
    },
    {
        id: "5",
        value: "Cool"
    },
    {
        id: "6",
        value: "Cool+2"
    },
    {
        id: "7",
        value: "Humidity"
    },
    {
        id: "8",
        value: "Humidity+2"
    },
    {
        id: "9",
        value: "CO2"
    },
    {
        id: "10",
        value: "CO2+2"
    }
]

export const typeAC = [
    {
        id:"0",
        value: "Empty"
    },
    {
        id: "1",
        value: "HAIER"
    },
    {
        id: "2",
        value: "SAMSUNG"
    },
    {
        id: "3",
        value: "LG"
    },
    {
        id: "4",
        value: "C&H"
    }
]