import {useContext, useState} from "react";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RoomStore from "../../store/RoomStore";

export interface ContextMenuProps {
    onCloseClick: (value: boolean) => void;
    onSaveColor: (name: string) => void;
    mac: string;
}

function RGBMenu(props: ContextMenuProps) {
    const roomStore = useContext(RoomStore);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState("0");

    // style={{
    //     backgroundColor: '#ffa500',
    // }}

    function selectMenu() {
        setOpen(!open);
        props.onCloseClick(false)
    }

    function saveColorAndSend(slot: string) {
        props.onSaveColor(slot)
    }

    function menuClick() {
        setOpen(!open);
    }

    // function getColor(colorName:string){
    //     roomStore.availableColors.find(r-> color.mac === props.mac && color.colorName === colorName);
    // }


    return (
        <>
            <div className="card__header-select" onClick={() => menuClick()}>
                <FontAwesomeIcon icon={solid('palette')} beatFade={true}/>
            </div>
            <div className={"card__header-mode " + (open ? "is-active" : "")}>
                <button className={"js-card-tab-btn " + (selected === "0" ? " is-state" : "")} type="button"
                        data-tab="1"
                        onClick={() => selectMenu()}>Main
                </button>
                <button className={"js-card-tab-btn " + (selected === "1" ? " is-state" : "")} data-tab="2"
                        type="button"
                        onClick={() => saveColorAndSend("color1")}>Save 1
                </button>
                <button className={"js-card-tab-btn " + (selected === "1" ? " is-state" : "")} data-tab="2"
                        type="button"
                        onClick={() => saveColorAndSend("color2")}>Save 2
                </button>
                <button className={"js-card-tab-btn " + (selected === "1" ? " is-state" : "")} data-tab="2"
                        type="button"
                        onClick={() => saveColorAndSend("color3")}>Save 3
                </button>
                <button className={"js-card-tab-btn " + (selected === "1" ? " is-state" : "")} data-tab="2"
                        type="button"
                        onClick={() => saveColorAndSend("color4")}>Save 4
                </button>
            </div>
        </>
    )
}

export default RGBMenu;