import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

function Spinner() {
    return (
        <div className="card__header-select" style={{marginRight: "50px"}}>
            <FontAwesomeIcon icon={solid('spinner')} spin={true}/>
        </div>
    )
}

export default Spinner;