import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

function Offline() {
    return (
        <div className="card__header-select" style={{marginRight: "50px", color: "red"}}>
            <FontAwesomeIcon icon={solid("triangle-exclamation")} beatFade={true}/>
        </div>
    )
}

export default Offline;