import {ManagementProps} from "./propTypes";
import React, {useContext, useEffect, useRef, useState} from "react";
import {runInAction} from "mobx";
import {observer} from "mobx-react-lite";
import RoomStore from "../store/RoomStore";
import {ComponentLineDTO} from "../../api/types";
import ContextMenu from "./menu/ContextMenu";
import Timers from "./menu/Timer";
import {clearTimeout, setTimeout} from "worker-timers";
import Spinner from "./info/Spinner";
import Offline from "./info/Offline";


function Patifon(props: ManagementProps) {

    const [cmpState, setCmpState] = useState<ComponentLineDTO>(props.line);
    const roomStore = useContext(RoomStore);
    const [selectedMenu, setSelectedMenu] = useState<string>("0");
    const [openTimer, setOpenTimer] = useState(false);
    const [onoff, setOnOff] = useState("0");
    const [play, setPlay] = useState("0");
    const [pause, setPause] = useState("0");
    const [speed, setSpeed] = useState("0");
    const [repeat, setRepeat] = useState("0");
    const [processing, setProcessing] = useState(false);
    const [offline, setOfflane] = useState(false);
    const processingRef = useRef(false);
    const [timeout, setTimeoutRef] = useState<number | null>(null);

    function onClick(topicName: string, value: string) {
        runInAction(() => {
            let updatedIndex = cmpState.topics.findIndex((p) => p.topicName === topicName);
            if (updatedIndex <= 0) {
                cmpState.topics.push({topicName, value})
            } else {
                let keyValue = cmpState.topics[updatedIndex];
                keyValue.value = value;
            }
        })
        // cmpState.topics.splice(updatedIndex, 1, device)
        if (topicName !== "onoff") {
            setCmpState(cmpState);
        }
        setOfflane(false);
        setProcessing(true);
        roomStore.updateManagementData(cmpState.id, {...cmpState, topics: [{topicName: topicName, value: value}]})
            .catch((error) => console.log(error));
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
        let timeoutValue = setTimeout(() => {
            if (processingRef.current) {
                setProcessing(false);
                setOfflane(true);
            }
        }, 10000)
        setTimeoutRef(timeoutValue);

    }

    function changeView(menuNumber: string) {
        setSelectedMenu(menuNumber);
    }

    function isMainSelected(): boolean {
        return selectedMenu === "0";
    }

    useEffect(() => {
        setCmpState(props.line);
        setProcessing(false);
        let outputValue = props.line.topics.find((p) => p.topicName === "onoff");
        outputValue && outputValue.value ? setOnOff(outputValue.value) : setOnOff("0");
        let pauseValue = props.line.topics.find((p) => p.topicName === "pause");
        pauseValue && pauseValue.value ? setPause(pauseValue.value) : setPause("0");
        let speedValue = props.line.topics.find((p) => p.topicName === "speed");
        speedValue && speedValue.value ? setSpeed(speedValue.value) : setSpeed("0");
        let repeatValue = props.line.topics.find((p) => p.topicName === "repeat");
        repeatValue && repeatValue.value ? setRepeat(repeatValue.value) : setRepeat("0");
        let playValue = props.line.topics.find((p) => p.topicName === "playstop");
        playValue && playValue.value ? setPlay(playValue.value) : setPlay("0");
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
    }, [props]);

    useEffect(() => {
        processingRef.current = processing;
    }, [processing]);

    return (
        <div className="col col-4 card-item">
            <div className="card">
                <div className="card__header">
                    <h3>{cmpState.name}</h3>
                    {processing &&
                        <Spinner/>
                    }
                    {offline &&
                        <Offline/>
                    }
                    <ContextMenu onMenuClick={changeView}/>
                </div>


                <div
                    className={"card__body card__body-with-icon d-flex align-items-center justify-content-end flex-wrap" + (openTimer ? " is-open-select " : "")}>
                    {isMainSelected() &&
                        <>
                            <div className="card__body--left">
                                <div className="card__body-radio">
                                    <div className="row">
                                        <div className="col col-4">
                                            <div className="item">
                                                <input type="radio" id={cmpState.id + "_ps_1"}
                                                       name={cmpState.id + "patifon_speed"} value="open"
                                                       checked={false}
                                                       onChange={() => onClick("speed", speed === "0" ? "1" : "0")}/>
                                                <label htmlFor={cmpState.id + "_ps_1"}
                                                       className="d-flex align-items-center justify-content-center">
                                                    <span>{speed === "0" ? "45" : "33"}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col col-4">
                                            <div className="item">
                                                <input type="radio" id={cmpState.id + "_pr_2"}
                                                       name={cmpState.id + "patifon_repeat"} value="repeat"
                                                       checked={repeat !== "0"}
                                                       onChange={() => onClick("repeat", repeat === "0" ? "1" : "0")}/>
                                                <label htmlFor={cmpState.id + "_pr2_2"}
                                                       className="d-flex align-items-center justify-content-center">
                                                    <span>repeat</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col col-4">
                                            <div className="item">
                                                <input type="radio" id={cmpState.id + "_pp_3"}
                                                       checked={pause !== "0"}
                                                       name={cmpState.id + "patifon_pause"} value="close"
                                                       onChange={() => onClick("pause", pause === "0" ? "1" : "0")}/>
                                                <label htmlFor={cmpState.id + "_pp_3"}
                                                       className="d-flex align-items-center justify-content-center">
                                                    <span>pause</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card__body-radio">
                                        <button
                                            style={{marginTop:'10px'}}
                                            className="btn btn-secondary"
                                            onClick={() => onClick("playstop", onoff === "1" ? "0" : "1")}
                                        >
                                            {play === "0" ? "Play" : "Stop"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card__body--right">
                                <button
                                    className={"card__body-btn d-flex align-items-center justify-content-center" + (onoff === "1" ? "  is-active" : "")}
                                    onClick={() => onClick("onoff", onoff === "1" ? "0" : "1")}
                                >
                                    < i className="icon-lamp"></i>
                                </button>
                            </div>
                        </>
                    }
                    {!isMainSelected() &&
                        <Timers type={5} cmp={cmpState.id} abbr={cmpState.abbreviation}
                                onOpenTimer={setOpenTimer}
                                submitValue={roomStore.updateTimerValue} getTimerData={roomStore.getTimerValues}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(Patifon);