import React, {useContext, useEffect, useState} from "react";
import ErrorField from "../errors/errorField";
import {Link, useNavigate} from "react-router-dom";
import AuthStore from "../store/AuthStore";
import {observer} from "mobx-react-lite";
import RoomStore from "../store/RoomStore";

function LoginContainer() {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [authError, setAuthError] = useState(false);
    const authStore = useContext(AuthStore)
    const roomStore = useContext(RoomStore)
    const navigate = useNavigate();

    function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (!password || !login) {
            setError(true);
            console.log(error);
            return;
        }
        let promise = authStore.login({userName: login, password: password});
        promise.then(function () {
            if (authStore.isLoggedIn) {
                setAuthError(false);
                roomStore.loadRooms().then(function () {
                    // roomStore.loadAvailableColors();
                    return roomStore.loadDeviceInfo();
                }).then(function () {
                    navigate("/cabinet", {replace: true});
                })
            } else {
                setAuthError(true);
            }
        }).catch((e)=>{
            console.error(e);
        })
    }

    useEffect(() => {
        authStore.clearSession();
    })

    return (
        <div className="auth">
            <div className="container d-flex align-items-center justify-content-center">
                <div className="row w100 justify-content-center">
                    <div className="col col-5">
                        <div className="card">
                            <div className="card__header">
                                <h3>Авторизация</h3>
                            </div>
                            <div className="card__body">
                                <form className="form mb8">
                                    <div className={`group h40 mb8 ${error ? 'has-error' : ""}`}>
                                        <input type="text" placeholder="Логин" value={login}
                                               onChange={(event) => setLogin(event.target.value)}/>
                                        {error ? <ErrorField/> : null}
                                    </div>
                                    <div className={`group h40 mb8 ${error ? 'has-error' : ""}`}>
                                        <input type="password" placeholder="Пароль" value={password}
                                               onChange={(event) => setPassword(event.target.value)}/>
                                        {error ? <ErrorField/> : null}
                                    </div>

                                    <div className="auth__control d-flex align-items-center justify-content-between">
                                        <div className="auth__control-save default-checkbox">
                                            <input type="checkbox" id="save-me"/>
                                            <label htmlFor="save-me">Запомнить</label>
                                        </div>

                                        <a href="reset.html" className="auth__control-reset">Забыли пароль?</a>
                                    </div>
                                    {authError ?
                                        <div className={`group h40 mb8 has-error`}>
                                            <ErrorField/>
                                        </div> : null}
                                    <button className="btn btn-primary" onClick={handleSubmit}>Войти в аккаунт</button>
                                </form>
                                <Link to="registration" className="btn btn-secondary">Регистрация</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(LoginContainer);

