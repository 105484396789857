import {ManagementProps} from "./propTypes";
import {useContext, useEffect, useRef, useState} from "react";
import {runInAction} from "mobx";
import {observer} from "mobx-react-lite";
import RoomStore from "../store/RoomStore";
import {ComponentLineDTO} from "../../api/types";
import ContextMenu from "./menu/ContextMenu";
import Timers from "./menu/Timer";
import {clearTimeout, setTimeout} from "worker-timers";
import Spinner from "./info/Spinner";
import Offline from "./info/Offline";


function Curtains(props: ManagementProps) {

    const [cmpState, setCmpState] = useState<ComponentLineDTO>(props.line);
    const roomStore = useContext(RoomStore);
    const [selectedMenu, setSelectedMenu] = useState<string>("0");
    const [openTimer, setOpenTimer] = useState(false);
    const [onoff, setOnOff] = useState("0");
    const [processing, setProcessing] = useState(false);
    const [offline, setOfflane] = useState(false);
    const processingRef = useRef(false);
    const [timeout, setTimeoutRef] = useState<number | null>(null);

    function onClick(topicName: string, value: string) {
        runInAction(() => {
            let updatedIndex = cmpState.topics.findIndex((p) => p.topicName === topicName);
            if (updatedIndex <= 0) {
                cmpState.topics.push({topicName, value})
            } else {
                let keyValue = cmpState.topics[updatedIndex];
                keyValue.value = value;
            }
        })
        // cmpState.topics.splice(updatedIndex, 1, device)
        if (topicName !== "onoff") {
            setCmpState(cmpState);
        }
        setOfflane(false);
        setProcessing(true);
        roomStore.updateManagementData(cmpState.id, {...cmpState, topics: [{topicName: topicName, value: value}]})
            .catch((error) => console.log(error));
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
        let timeoutValue = setTimeout(() => {
            if (processingRef.current) {
                setProcessing(false);
                setOfflane(true);
            }
        }, 10000)
        setTimeoutRef(timeoutValue);

    }

    function changeView(menuNumber: string) {
        setSelectedMenu(menuNumber);
    }

    function isMainSelected(): boolean {
        return selectedMenu === "0";
    }

    useEffect(() => {
        setCmpState(props.line);
        setProcessing(false);
        let outputValue = props.line.topics.find((p) => p.topicName === "onoff");
        if (outputValue && outputValue.value) {
            setOnOff(outputValue.value);
        } else {
            setOnOff("0");
        }
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
    }, [props]);

    useEffect(() => {
        processingRef.current = processing;
    }, [processing]);

    return (
        <div className="col col-4 card-item">
            <div className="card">
                <div className="card__header">
                    <h3>{cmpState.name}</h3>
                    {processing &&
                        <Spinner/>
                    }
                    {offline &&
                        <Offline/>
                    }
                    <ContextMenu onMenuClick={changeView}/>
                </div>


                <div
                    className={"card__body card__body-with-icon d-flex align-items-center justify-content-end flex-wrap"+ ( openTimer ? " is-open-select " : "")}>
                    {isMainSelected() &&
                        <>
                            <div className="card__body--left">

                                <div className="card__body-radio">
                                    <div className="row">
                                        <div className="col col-4">
                                            <div className="item">
                                                <input type="radio" id={cmpState.id + "_cc_1"}
                                                       name={cmpState.id + "curtain_control"} value="open"
                                                       checked={false}
                                                       onChange={() => onClick("onoff", "1")}/>
                                                <label htmlFor={cmpState.id + "_cc_1"}
                                                       className="d-flex align-items-center justify-content-center">
                                                    <span>Открыть</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col col-4">
                                            <div className="item">
                                                <input type="radio" id={cmpState.id + "_cc_2"}
                                                       name={cmpState.id + "curtain_control"} value="stop"
                                                       checked={false}
                                                       onChange={() => onClick("stop", "1")}/>
                                                <label htmlFor={cmpState.id + "_cc_2"}
                                                       className="d-flex align-items-center justify-content-center">
                                                    <span>Стоп</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col col-4">
                                            <div className="item">
                                                <input type="radio" id={cmpState.id + "_cc_3"}
                                                       checked={false}
                                                       name={cmpState.id + "curtain_control"} value="close"
                                                       onChange={() => onClick("onoff", "0")}/>
                                                <label htmlFor={cmpState.id + "_cc_3"}
                                                       className="d-flex align-items-center justify-content-center">
                                                    <span>Закрыть</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card__body--right">
                                <button type="button"
                                        onClick={() => onClick("onoff", onoff === "1" ? "0" : "1")}
                                        className={"card__body-btn d-flex align-items-center justify-content-center flex-wrap "}>
                                    <i className={"icon-curtain " + (onoff === "1" ? "  white" : "")}></i>
                                </button>
                            </div>
                        </>
                    }
                    {!isMainSelected() &&
                        <Timers type={5} cmp={cmpState.id} abbr={cmpState.abbreviation}
                                onOpenTimer={setOpenTimer}
                                submitValue={roomStore.updateTimerValue} getTimerData={roomStore.getTimerValues}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(Curtains);