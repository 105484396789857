import TimerLine from "./TimerLine";
import {KeyValue, TimerRequestDTO, TimerUpdateDTO} from "../../../api/types";
import {useEffect, useState} from "react";

export interface TimersProps {
    type: number;
    cmp: number;
    abbr: string;
    dropList?: KeyValue[];
    submitValue: (request: TimerUpdateDTO) => void;
    onOpenTimer?: (open: boolean) => void;
    getTimerData: (request: TimerRequestDTO) => Promise<{ [name: string]: string }>
}

function Timers(props: TimersProps) {
    const [timerData, setTimerData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.getTimerData({id: props.cmp, topic: props.abbr}).then(result => {
            setTimerData(result);
            setLoading(false);
        });
    }, [props]);

    return (

        <div className="card__body-schedule">
            {!loading &&
                Array.from(Array(4).keys()).map(value => {
                    return <TimerLine id={value + 1} key={value} type={props.type} cmp={props.cmp} abbr={props.abbr}
                                      dropList={props.dropList}
                                      onOpenTimer={props.onOpenTimer} submitValue={props.submitValue}
                                      timerData={timerData}/>
                })
            }
        </div>
    )
}

export default Timers;