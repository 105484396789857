import {observer} from "mobx-react-lite";
import React, {useContext, useState} from "react";
import RoomStore from "../store/RoomStore";

function AddRoom() {

    const roomStore = useContext(RoomStore);
    const [toggled, setToggled] = useState(false);
    const [roomName, setRoomName] = useState("");

    function addNewRoom(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (roomName){
            roomStore.addRoom({id:-1, name:roomName}).then(()=> setRoomName(""));
        }
    }


    function toggleComponent() {
        setToggled(!toggled);
    }

    function toggleForm(){
        if (!toggled){
            roomStore.loadRooms().then((result)=>{
                toggleComponent();
            });
        } else {
            toggleComponent();
        }
    }

    function deleteRoom(id: number, event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (id <= 0) {
            return;
        }
        roomStore.removeRoom(id);
    }

    return (
        <div className="card">
            <div className={`card__header card__header--toggle ${toggled ? "is-open" : ""}`}
                 onClick={toggleForm}>
                <h3>Добавить комнату</h3>
                <i className="icon-angle-down"></i>
            </div>
            <div className="card__body">
                <form action="" className="form mb8">
                    <div className="group h40 mb8">
                        <input type="text" placeholder="Введите название комнаты" value={roomName}
                               onChange={(event) => setRoomName(event.target.value)}/>
                    </div>
                    {roomStore.roomList.map(room =>
                        (<div className="group group-with-btn р40 mb8" key={"key" + room.id}
                              data-value={room.id}>
                            <input type="text" disabled readOnly value={room.name}/>
                            <button
                                className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                                type="button"
                                onClick={(event) => deleteRoom(room.id, event)}>
                                <i className="icon-remove"></i>
                            </button>

                        </div>)
                    )}
                    <button className="btn btn-primary" onClick={addNewRoom}>Создать</button>
                </form>
            </div>

        </div>
    )
}

export default observer(AddRoom);