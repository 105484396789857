import React, {useContext, useEffect, useState} from "react";
import RoomStore from "../store/RoomStore";
import {observer} from "mobx-react-lite";
import Relay from "../managment/Relay";
import Button from "../managment/Button";
import Dimmer from "../managment/Dimmer";
import DimmerL from "../managment/DimmerL";
import TemperatureController from "../managment/TempratureController";
import Curtains from "../managment/Curtains";
import Macros from "../managment/Macros";
import Conditioner from "../managment/Conditioner";
import {clearInterval, setInterval} from 'worker-timers';
import DimmerW from "../managment/DimmerW";
import Radio from "../managment/Radio";
import Patifon from "../managment/Patifon";


function RoomContainer() {

    const roomStore = useContext(RoomStore);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (roomStore.selectedRoom !== undefined) {
            console.log("selected");
            roomStore.loadRoomLines().then(() => {
                setLoading(false);
            })
        }
    }, [roomStore.selectedRoom]);

    function updateRoomState() {
        roomStore.updateRoomLines();
    }

    useEffect(() => {
        const timer = setInterval(() => {
            updateRoomState();
        }, 1000);
        return () => clearInterval(timer);

    }, [roomStore.selectedRoom]);

    return (
        <div className="container">
            <div className="row justify-content-start align-content-stretch flex-wrap">
                {!loading && roomStore.listOfDevices.map(line => {
                        if (line.type === "relay") {
                            return (<Relay key={line.id} line={line}/>)
                        }
                        if (line.type === "button") {
                            return (<Button key={line.id} line={line}/>)
                        }
                        if (line.type === "dimmer") {
                            return (<Dimmer key={line.id} line={line}/>)
                        }
                        if (line.type === "dimmerL") {
                            return (<DimmerL key={line.id} line={line}/>)
                        }
                        if (line.type === "dimmerW") {
                            return (<DimmerW key={line.id} line={line}/>)
                        }
                        if (line.type === "temperature") {
                            return (<TemperatureController key={line.id} line={line}/>)
                        }
                        if (line.type === "curtains") {
                            return (<Curtains key={line.id} line={line}/>)
                        }
                        if (line.type === "macros") {
                            return (<Macros key={line.id} line={line}/>)
                        }
                        if (line.type === "conditioner") {
                            return (<Conditioner key={line.id} line={line}/>)
                        }
                        if (line.type === "radio") {
                            return (<Radio key={line.id} line={line}/>)
                        }
                        if (line.type === "patifon") {
                            return (<Patifon key={line.id} line={line}/>)
                        }
                    }
                )
                }
            </div>
        </div>
    )
};

export default observer(RoomContainer);