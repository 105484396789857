import {colors, ManagementProps, modes, modesW} from "./propTypes";
import {useContext, useEffect, useRef, useState} from "react";
import {ComponentLineDTO} from "../../api/types";
import {runInAction} from "mobx";
import {observer} from "mobx-react-lite";
import RoomStore from "../store/RoomStore";
import Slider from "rc-slider";
import {SlideValue, Thumb} from "../controls/Common";
import ContextMenu from "./menu/ContextMenu";
import Timers from "./menu/Timer";
import Spinner from "./info/Spinner";
import Offline from "./info/Offline";
import {clearTimeout, setTimeout} from "worker-timers";


function DimmerW(props: ManagementProps) {

    const [cmpState, setCmpState] = useState<ComponentLineDTO>(props.line);
    const [open, setOpen] = useState(false);
    const [openTimer, setOpenTimer] = useState(false);
    const roomStore = useContext(RoomStore);
    const [selectedMenu, setSelectedMenu] = useState<string>("0");
    const [onoff, setOnOff] = useState("0");
    const [processing, setProcessing] = useState(false);
    const [offline, setOfflane] = useState(false);
    const processingRef = useRef(false);
    const [timeout, setTimeoutRef] = useState<number | null>(null);

    useEffect(() => {
        setCmpState(props.line);
        setProcessing(false);
        let outputValue = props.line.topics.find((p) => p.topicName === "onoff");
        if (outputValue && outputValue.value) {
            setOnOff(outputValue.value);
        } else {
            setOnOff("0");
        }
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
    }, [props]);

    useEffect(() => {
        processingRef.current = processing;
    }, [processing]);

    function changeView(menuNumber: string) {
        setSelectedMenu(menuNumber);
    }

    function isMainSelected(): boolean {
        return selectedMenu === "0";
    }

    function onClick(topicName: string, value: number | string | number[], submit: boolean = true) {
        runInAction(() => {
            let updatedIndex = cmpState.topics.findIndex((p) => p.topicName === topicName);
            let keyValue = cmpState.topics[updatedIndex];
            keyValue.value = "" + value;
            if (topicName === "color") {
                setSelectedMode("" + value);
            }
        })
        if (topicName !== "onoff") {
            setCmpState(cmpState);
        }
        if (submit) {
            setOfflane(false);
            setProcessing(true);
            submitData(topicName, "" + value);
            if (timeout) {
                clearTimeout(timeout);
                setTimeoutRef(null);
            }
            let timeoutValue = setTimeout(() => {
                if (processingRef.current) {
                    setProcessing(false);
                    setOfflane(true);
                }
            }, 10000)
            setTimeoutRef(timeoutValue);
        }
    }

    function getSliderValue() {
        let value = cmpState.topics.find(key => key.topicName === 'brightness')?.value;
        if (value && value !== "") {
            return +value;
        }
        return 0;
    }

    function submitData(topicName: string, s: string) {
        roomStore.updateManagementData(cmpState.id, {...cmpState, topics: [{topicName: topicName, value: s}]})
            .catch((error) => console.log(error));
    }

    const [selectedMode, setSelectedMode] = useState(cmpState.topics.find(key => key.topicName === "color")?.value);

    return (
        <div className="col col-4 card-item">
            <div className="card">
                <div className="card__header">
                    <h3>{cmpState.name}</h3>
                    {processing &&
                        <Spinner/>
                    }
                    {offline &&
                        <Offline/>
                    }
                    <ContextMenu onMenuClick={changeView}/>
                </div>


                <div
                    className={"card__body card__body-with-icon d-flex align-items-center justify-content-end flex-wrap" + (open || openTimer ? " is-open-select " : "")}>
                    {isMainSelected() &&
                        <>
                            <div className="card__body--left">
                                <div className="range-slider">
                                    <div className="slider">
                                        <Slider
                                            value={getSliderValue()}
                                            min={0}
                                            max={100}
                                            onChange={(event) => onClick("brightness", event, false)}
                                            onAfterChange={(event) => onClick("brightness", event, true)}
                                            handleRender={renderProps => {
                                                return (
                                                    <div {...renderProps.props}>
                                                        <Thumb
                                                            className="noUi-handle noUi-handle-lower">
                                                            <div className="noUi-touch-area"></div>
                                                            <SlideValue
                                                                className="noUi-tooltip">   {cmpState.topics.find(key => key.topicName === 'brightness')?.value}%
                                                            </SlideValue>
                                                        </Thumb>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className={"selectWrapper mb12" + (open ? " is-active" : "")}
                                     onClick={() => setOpen(!open)}>
                                    <div className="selectCustom h49 js-selectCustom">
                                        <div
                                            className="selectCustom-trigger"
                                        >{modesW.find(key => key.id === selectedMode)?.value}</div>
                                        <div className="selectCustom-options">
                                            {modesW.map((value) =>
                                                <div className="selectCustom-option" data-value={value.id}
                                                     key={value.id}
                                                     onClick={() => onClick("color", value.id)}>{value.value}</div>
                                            )}

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="card__body--right">
                                <button
                                    className={"card__body-btn d-flex align-items-center justify-content-center" + (onoff === "1" ? "  is-active" : "")}
                                    onClick={() => onClick("onoff", onoff === "1" ? "0" : "1")}
                                >
                                    < i className="icon-lamp"></i>
                                </button>
                            </div>
                        </>
                    }
                    {!isMainSelected() &&
                        <Timers type={3} cmp={cmpState.id} abbr={cmpState.abbreviation}
                                onOpenTimer={setOpenTimer}
                                submitValue={roomStore.updateTimerValue} getTimerData={roomStore.getTimerValues}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default observer
(DimmerW);