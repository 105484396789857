import {ComponentGroupDTO} from "../../api/types";
import React, {useState} from "react";
import LineComponent from "./LineComponent";

function GroupContainer(props: ComponentGroupDTO) {

    const [open, setOpen] = useState(false);

    return (
        <div className={"server__table-sub "   + (open ? " is-open" : "")} data-type={props.groupAbbreviation}>
            <div className="server__table-sub-thead js-open--sub-table mb8">
                <span>{props.groupName + " - " + props.groupAbbreviation}</span>
                <button
                    className="server__table-sub--toggle d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                    onClick={() => setOpen(!open)}>
                    <i className="icon-angle-down"></i>
                </button>
            </div>
            {open &&
                <div className="server__table-sub-tbody">
                    {props.lines.map((line) =>
                        (<LineComponent {...line} multiline={props.multiline} key={line.id}/>)
                    )
                    }
                </div>
            }
        </div>
    );
}

export default GroupContainer;