import {useEffect, useState} from "react";
import {KeyValue, TimerUpdateDTO} from "../../../api/types";

export interface TimerLineProps {
    id: number;
    type: number;
    cmp: number;
    abbr: string;
    dropList?: KeyValue[];
    submitValue: (request: TimerUpdateDTO) => void;
    onOpenTimer?: (open: boolean) => void;
    timerData: { [name: string]: string };
}

function TimerLine(props: TimerLineProps) {
    const [hour, setHour] = useState("Час")
    const [minutes, setMinutes] = useState("Мин.")
    const [openHours, setOpenHours] = useState(false)
    const [openMinutes, setOpenMinutes] = useState(false)
    const [operation, setOperation] = useState("")
    const [checked, setChecked] = useState(false)
    const [openOperation, setOperationOpen] = useState(false)

    useEffect(() => {
        let opData = props.timerData["Set" + props.id];
        if (opData) {
            setOperationValue(opData, false);
        }
        let mData = props.timerData["Set" + props.id + "m"];
        if (mData) {
            setMinutesValue(mData, false);
        }
        let hData = props.timerData["Set" + props.id + "h"];
        if (hData) {
            setHoursValue(hData, false);
        }
        let onData = props.timerData["Set" + props.id + "onoff"];
        if (onData) {
            onEnableChange(onData === "1", false);
        }
    }, [props])

    function HourLine(value: string, mes: string = "") {
        return (
            <div className="selectCustom-option" key={value} onClick={() => setHoursValue(value)}>{value + mes}</div>)
    }

    function operationLine(value: string, mes: string = "") {
        return (
            <div className="selectCustom-option" key={value}
                 onClick={() => setOperationValue(value)}>{value + mes}</div>)
    }

    function setMinutesValue(value: string, submit: boolean = true) {
        setMinutes(value);
        setOpenMinutes(false);
        if (submit) {
            let topicName = "Set" + props.id + "m"
            submitTopic(topicName, value);
        }
    }

    function setHoursValue(value: string, submit: boolean = true) {
        setHour(value);
        setOpenHours(false);
        if (submit) {
            let topicName = "Set" + props.id + "h"
            submitTopic(topicName, value);
        }
    }

    function getMeasurements() {
        if (props.type === 2) {
            return "";
        } else if (props.type === 3) {
            return "%";
        } else if (props.type === 3) {
            return "C";
        } else if (props.type === 4) {
            return "℃";
        }
    }

    function setOperationValue(value: string, submit: boolean = true) {
        if (value === "on") {
            setOperation("Вкл")
        } else if (value === "off") {
            setOperation("Выкл")
        } else if (value === "open") {
            setOperation("Откр.")
        } else if (value === "close") {
            setOperation("Закр.")
        } else if (value === "START") {
            setOperation("Старт")
        } else if (props.type === 7) {
            let selectedTopic = props.dropList?.find(rec => rec.topicName === value);
            if (selectedTopic !== undefined) {
                setOperation(selectedTopic.topicName);
            }
        } else {
            setOperation(value + getMeasurements())
        }
        setOperationOpen(false);
        if (submit) {
            let topicName = "Set" + props.id
            submitTopic(topicName, value);
        }
    }

    function generateOperationColumn() {
        let result = [];
        if (props.type === 1) {
            result.push(<div className="selectCustom-option" key={"on"}
                             onClick={() => setOperationValue("on")}>Вкл</div>);
            result.push(<div className="selectCustom-option" key={"off"}
                             onClick={() => setOperationValue("off")}>Выкл</div>);
        } else if (props.type === 2) {
            result.push(<div className="selectCustom-option" key={"on"}
                             onClick={() => setOperationValue("on")}>Вкл</div>);
            result.push(<div className="selectCustom-option" key={"off"}
                             onClick={() => setOperationValue("off")}>Выкл</div>);
            result.push(<div className="selectCustom-option" key={"3min"}
                             onClick={() => setOperationValue("3min")}>3min</div>);
            result.push(<div className="selectCustom-option" key={"5min"}
                             onClick={() => setOperationValue("5min")}>5min</div>);
            result.push(<div className="selectCustom-option" key={"10min"}
                             onClick={() => setOperationValue("10min")}>10min</div>);
        } else if (props.type === 3) {
            result.push(<div className="selectCustom-option" key={"on"}
                             onClick={() => setOperationValue("on")}>Вкл</div>);
            result.push(<div className="selectCustom-option" key={"off"}
                             onClick={() => setOperationValue("off")}>Выкл</div>);
            result.push(<div className="selectCustom-option" key={"1"}
                             onClick={() => setOperationValue("1")}>{"1" + getMeasurements()}</div>);
            result.push(Array.from(Array(20).keys()).map(x => (x * 5) + 5).map(value => {
                return operationLine("" + value, getMeasurements());
            }));
        } else if (props.type === 4) {
            result.push(<div className="selectCustom-option" key={"on"}
                             onClick={() => setOperationValue("on")}>Вкл</div>);
            result.push(<div className="selectCustom-option" key={"off"}
                             onClick={() => setOperationValue("off")}>Выкл</div>);
            for (let i = 15; i <= 35; i++) {
                result.push(operationLine("" + i, getMeasurements()))
            }
        } else if (props.type === 5) {
            result.push(<div className="selectCustom-option" key={"on"}
                             onClick={() => setOperationValue("open")}>Откр.</div>);
            result.push(<div className="selectCustom-option" key={"off"}
                             onClick={() => setOperationValue("close")}>Закр.</div>);
        } else if (props.type === 6) {
            result.push(<div className="selectCustom-option" key={"start"}
                             onClick={() => setOperationValue("START")}>Старт</div>);
        } else if (props.type === 7) {
            result.push(<div className="selectCustom-option" key={"on"}
                             onClick={() => setOperationValue("open")}>Вкл.</div>);
            result.push(<div className="selectCustom-option" key={"off"}
                             onClick={() => setOperationValue("close")}>Выкл.</div>);
            props.dropList?.map(record => {
                result.push(<div className="selectCustom-option" key={record.topicName}
                                 onClick={() => setOperationValue(record.topicName)}>{record.value}</div>);
            })

        }

        return result;
    }

    function minuteLine(value: string) {
        return (<div className="selectCustom-option" key={value} onClick={() => setMinutesValue(value)}>{value}</div>)
    }

    function getMinutesIntervals() {
        return Array.from(Array(12).keys()).map(x => (x * 5))
    }

    function onEnableChange(checked: boolean, submit: boolean = true) {
        setChecked(checked);
        if (submit) {
            let topicName = "Set" + props.id + "onoff";
            submitTopic(topicName, checked ? "1" : "0");
        }
    }

    function submitTopic(topicName: string, value: string) {
        console.log("Value submited ", value, " to topic ", topicName);
        props.submitValue({id: props.cmp, topic: topicName, value: value});
    }

    function setOpenDropdown(value: boolean, type: number) {
        if (type === 1) {
            setOpenHours(value);
        } else if (type === 2) {
            setOpenMinutes(value);
        } else if (type === 3) {
            setOperationOpen(value);
        }
        if (props.onOpenTimer !== undefined) {
            props.onOpenTimer(value);
        }
    }

    return (

        <div className="item d-flex flex-wrap">
            <div className="item__time d-flex justify-content-between">
                <div className="group">
                    <div className={"selectWrapper" + (openHours ? " is-active" : "")}>
                        <div className="selectCustom h26 js-selectCustom text-center">
                            <div className="selectCustom-trigger"
                                 onClick={() => setOpenDropdown(!openHours, 1)}>{hour}</div>
                            <div className="selectCustom-options">
                                {Array.from(Array(24).keys()).map(value => {
                                    return HourLine("" + (value + 1));
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="group">
                    <div className={"selectWrapper" + (openMinutes ? " is-active" : "")}>
                        <div className="selectCustom h26 js-selectCustom text-center">
                            <div className="selectCustom-trigger"
                                 onClick={() => setOpenDropdown(!openMinutes, 2)}>{minutes}</div>
                            <div className="selectCustom-options">
                                {getMinutesIntervals().map(value => {
                                    return minuteLine("" + value);
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item__status">
                <div className={"selectWrapper" + (openOperation ? " is-active" : "")}>
                    <div className="selectCustom h26 js-selectCustom">
                        <div className="selectCustom-trigger"
                             onClick={() => setOpenDropdown(!openOperation, 3)}>{operation}</div>
                        <div className="selectCustom-options">
                            {generateOperationColumn()}
                        </div>
                    </div>
                </div>

            </div>
            <div className="item__action">
                <div className="switch">
                    <input className="switch-input" type="checkbox" checked={checked}
                           id={"enable_" + props.cmp + "__" + props.abbr + "__" + props.id}
                           onChange={(e) => onEnableChange(e.target.checked)}/>
                    <label className="switch-label"
                           htmlFor={"enable_" + props.cmp + "__" + props.abbr + "__" + props.id}>
                        <label className="switch-label-aux"
                               htmlFor={"enable_" + props.cmp + "__" + props.abbr + "__" + props.id}>
                        </label>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default TimerLine;

