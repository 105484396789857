import {observer} from "mobx-react-lite";
import {ManagementProps} from "./propTypes";
import {useContext, useEffect, useRef, useState} from "react";
import {ComponentLineDTO} from "../../api/types";
import {runInAction} from "mobx";
import RoomStore from "../store/RoomStore";
import ContextMenu from "./menu/ContextMenu";
import Timers from "./menu/Timer";
import Spinner from "./info/Spinner";
import Offline from "./info/Offline";
import {clearTimeout, setTimeout} from "worker-timers";

function Relay(props: ManagementProps) {

    const [cmpState, setCmpState] = useState<ComponentLineDTO>(props.line);
    const [selectedMenu, setSelectedMenu] = useState<string>("0");
    const roomStore = useContext(RoomStore);
    const [openTimer, setOpenTimer] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [offline, setOfflane] = useState(false);
    const processingRef = useRef(false);
    const [onoff, setOnOff] = useState("0");
    const [timeout, setTimeoutRef] = useState<number | null>(null);

    function onClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        runInAction(() => {
            let updatedIndex = cmpState.topics.findIndex((p) => p.topicName === "onoff");
            let keyValue = cmpState.topics[updatedIndex];
            keyValue.value = keyValue.value === "0" ? "1" : "0";
        })
        // setCmpState(cmpState);
        setOfflane(false);
        setProcessing(true);
        roomStore.updateManagementData(cmpState.id, cmpState)
            .catch((error) => console.log(error));
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
        let timeoutValue = setTimeout(() => {
            if (processingRef.current) {
                setProcessing(false);
                setOfflane(true);
            }
        }, 10000)
        setTimeoutRef(timeoutValue);


    }

    function changeView(menuNumber: string) {
        setSelectedMenu(menuNumber);
    }

    function isMainSelected(): boolean {
        return selectedMenu === "0";
    }

    useEffect(() => {
        setCmpState(props.line);
        setProcessing(false);
        let outputValue = props.line.topics.find((p) => p.topicName === "onoff");
        if (outputValue && outputValue.value) {
            setOnOff(outputValue.value);
        } else {
            setOnOff("0");
        }
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutRef(null);
        }
    }, [props]);

    useEffect(() => {
        processingRef.current = processing;
    }, [processing]);


    return (
        <div className="col col-4 card-item">
            <div className="card">
                <div className="card__header">
                    <h3>{cmpState.name}</h3>
                    {processing &&
                        <Spinner/>
                    }

                    {offline &&
                        <Offline/>
                    }
                    <ContextMenu onMenuClick={changeView}/>
                </div>
                <div
                    className={"card__body card__body-with-icon d-flex align-items-center justify-content-end flex-wrap " + (openTimer ? " is-open-select " : "")}>
                    {isMainSelected() &&
                        <div className="card__body--right">
                            <button type="button"
                                    className={"card__body-btn d-flex align-items-center justify-content-center" + (onoff === "1" ? "  is-active" : "")}
                                    onClick={(event) => onClick(event)}
                            >
                                <i className="icon-lamp"></i>
                            </button>
                        </div>

                    }
                    {!isMainSelected() &&
                        <Timers type={1} cmp={cmpState.id} abbr={cmpState.abbreviation}
                                onOpenTimer={setOpenTimer}
                                submitValue={roomStore.updateTimerValue} getTimerData={roomStore.getTimerValues}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default observer(Relay)