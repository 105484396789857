import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import AuthStore from "../store/AuthStore";
import RoomStore from "../store/RoomStore";

function UserIcon() {

    const navigate = useNavigate();
    const authStore = useContext(AuthStore)
    const roomStore = useContext(RoomStore)

    function logout(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        authStore.clearSession();
        roomStore.clearData();
        navigate("/", {replace: true});
    }

    return (
        <div className="header__user">
            <a className="d-flex align-items-center justify-content-center" onClick={(event) => logout(event)}>
                <i className="icon-user"></i>
            </a>
        </div>
    )
};

export default UserIcon;