import {LoginResponse} from "../../api/types";

export default class AuthToken {

    private expireDate: Date;
    private jwtToken: string;
    private refreshTokenExpireDate: Date = new Date();
    private refreshToken: string = '';


    constructor(authResponse: LoginResponse) {
        if (authResponse) {
            this.expireDate = new Date(Date.parse(authResponse.expireDate));
            this.jwtToken = authResponse.jwtToken;
            this.refreshTokenExpireDate = new Date(Date.parse(authResponse.refreshTokenExpireDate));
            this.refreshToken = authResponse.refreshToken;
        } else {
            this.expireDate = new Date();
            this.jwtToken = '';
            this.refreshTokenExpireDate = new Date();
            this.refreshToken = '';
        }
    }


    get refresh(): string {
        return this.refreshToken;
    }

    get accessToken(): string {
        return this.jwtToken;
    }

    get refreshTokenExpired(): boolean {
        return Date.now() >= new Date(this.refreshTokenExpireDate).getTime();
    }

    get accessTokenExpired(): boolean {
        return Date.now() >= new Date(this.expireDate).getTime();
    }

}