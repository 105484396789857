import {useState} from "react";

export interface ContextMenuProps {
    onMenuClick: (value: string) => void;
}

function ContextMenu(props: ContextMenuProps) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState("0");

    function selectMenu(menu: string) {
        if (menu !== selected) {
            setSelected(menu);
            props.onMenuClick(menu);
        }
        setOpen(!open);
    }

    return (
        <>
            <div className="card__header-select" onClick={() => setOpen(!open)}>
                <span></span><span></span><span></span>
            </div>
            <div className={"card__header-mode " + (open ? "is-active" : "")}>
                <button className={"js-card-tab-btn " + (selected === "0" ? " is-state" : "")} type="button" data-tab="1"
                        onClick={() => selectMenu("0")}>Main
                </button>
                <button className={"js-card-tab-btn " + (selected === "1" ? " is-state" : "")} data-tab="2" type="button"
                        onClick={() => selectMenu("1")}>Time
                </button>
            </div>
        </>
    )
}

export default ContextMenu;