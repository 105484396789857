import {ClientDeviceDTO, ComponentGroupDTO} from "../../api/types";
import React, {useContext, useState} from "react";
import RoomStore from "../store/RoomStore";
import GroupContainer from "./GroupLineContainer";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function DeviceContainer(props: ClientDeviceDTO) {

    const [info, setInfo] = useState(props);
    const [open, setOpen] = useState(false);
    const [groups, setGroups] = useState<ComponentGroupDTO[]>([])
    const roomStore = useContext(RoomStore);

    function setValue(value: string, field: string) {
        setInfo({...info, [field]: value})
    }

    function updateDevice(event: React.MouseEvent<HTMLButtonElement>) {
        console.info(info);
        event.preventDefault();
        if (!info.name || !info.knx || !info.mac) {
            return;
        }
        console.info(info);
        if (info.id !== undefined) {
            roomStore.updateClientDevice(info.id, info).then(() => {
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    function updateFirmware(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (info.modelst === info.update) {
            return;
        }
        if (info.id !== undefined) {
            confirmAlert({
                message: 'Вы уверены что хотите обновить прошивку для устройства?',
                buttons: [
                    {
                        label: 'Нет'
                    },
                    {
                        label: 'Да',
                        onClick: () => {
                            // if (typeof info.id === "number") {
                            //     roomStore.updateClientDevice(info.id, info).then(() => {
                            //     }).catch((error) => {
                            //         console.log(error)
                            //     });
                            // }
                        }
                    }
                ]
            });
        }
    }

    function deleteDevice(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (info.id !== undefined) {
            confirmAlert({
                message: 'Вы уверены что хотите удалить устройство?',
                buttons: [
                    {
                        label: 'Нет'
                    },
                    {
                        label: 'Да',
                        onClick: () => {
                            if (typeof info.id === "number") {
                                roomStore.deleteClientDevice(info.id).then(() => {
                                }).catch((error) => {
                                    console.log(error)
                                });
                            }
                        }
                    }
                ]
            });
        }
    }

    function loadData() {
        if (open) {
            setOpen(!open);
        } else {
            if (props.id) {
                roomStore.getDeviceGroups(props.id).then(groups => {
                    setGroups(groups);
                    setOpen(!open);
                }).catch(e => {
                    console.log(e);
                })
            }
        }
    }

    return (
        <div className={"server__table bg-noise" + (open ? " is-open-main" : "")}>
            <div className={"server__table-thead d-flex flex-wrap blue-border "}>
                <div className="server__table-th server__table-th--name blue" onClick={loadData}>
                    <span>{roomStore.getDeviceInfo(info.deviceType)}</span>

                    <div className="buttons">
                        <button className="js-modal-opener" data-modal="#smr"><i className="icon-edit"></i></button>
                        <button className="js-toggle-table"><i className="icon-angle-down"></i></button>
                    </div>
                </div>

                <div className="server__table-th server__table-th--action">
                    <div className="group">
                        <input type="text" value={info.name} placeholder="Название"
                               onChange={(event) => setValue(event.target.value, "name")}/>
                    </div>
                </div>

                <div className="server__table-th server__table-th--ipv4">
                    <div className="group">
                        <input type="text" value={info.knx}
                               onChange={(event) => setValue(event.target.value, "knx")}/>
                    </div>
                </div>

                <div className="server__table-th server__table-th--ipv6">
                    <div className="group">
                        <input type="text" value={info.mac}
                               onChange={(event) => setValue(event.target.value, "mac")}/>
                    </div>
                </div>

                <div className="server__table-th server__table-th--status">
                    <div className="group">
                        <input type="text" value={info.modelst}
                               onChange={(event) => setValue(event.target.value, "update")}/>
                    </div>
                </div>

                <div className="server__table-th server__table-th--control">
                    <button
                        className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                        onClick={updateFirmware}>
                        <i className="icon-download"></i></button>
                    <button
                        className="server__forms-control js-open-table d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                        onClick={updateDevice}>
                        <i className="icon-save"></i></button>

                    <button
                        className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10"
                        onClick={deleteDevice}>
                        <i className="icon-remove"></i></button>
                </div>
            </div>
            {open &&
                (<div className="server__table-tbody">
                    {groups.map((group) =>
                        (<GroupContainer key={"key" + group.id} {...group}/>)
                    )}
                </div>)
            }
        </div>
    );
}

export default DeviceContainer;