import Logo from "../controls/Logo";
import RoomSelector from "../controls/RoomSelector";
import {useContext} from "react";
import AuthStore from "../store/AuthStore";
import UserIcon from "../controls/UserIcon";
import {observer} from "mobx-react-lite";

function HeaderContainer() {
    const authStore = useContext(AuthStore)
    return (
        <div className="container">
            <header className="header d-flex align-items-center justify-content-between flex-wrap">
                <Logo/>
                {authStore.isLoggedIn &&
                    <>
                        <RoomSelector/>
                        <UserIcon/>
                    </>
                }
            </header>
        </div>
    )

}

export default observer(HeaderContainer);