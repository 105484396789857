import styled from "styled-components";

export const Thumb = styled.div`
  /* This renders the buttons above... Edit me! */
  position: absolute;
  z-index: 2;
  width: 50px;
  height: 25px;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
  outline: 0;
  border-radius: 56px !important;
  bottom: auto;
  top: -7px;
  left: -20px;
`

export const SlideValue = styled.div`
  /* This renders the buttons above... Edit me! */
  position: relative;
  bottom: 20px;
`