import {ComponentLineDTO, RoomDTO} from "../../api/types";
import {useContext, useState} from "react";
import RoomStore from "../store/RoomStore";
import {buttonModes, typeAC, typeTH} from "../managment/propTypes";

interface ComponentLineProps extends ComponentLineDTO {
    multiline: boolean;
}

interface SpecialTopicState {
    topicName: string;
    open: boolean;
}

function LineComponent(props: ComponentLineProps) {
    const [line, setLine] = useState<ComponentLineDTO>(props);
    const [roomSelector, setRoomSelector] = useState(false);
    const [send, setSend] = useState(true);
    const roomStore = useContext(RoomStore);
    const [openSpecialTopics, setOpenSpecialTopics] = useState<SpecialTopicState[]>([])

    function setValue(value: string | number | null, field: string) {
        setLine({...line, [field]: value});
    }

    function setTopicValue(value: string, topicName: string) {
        let updateItem = line.topics.find(keyValue => keyValue.topicName === topicName);
        if (updateItem) {
            let newArr = [...line.topics]
            let index = newArr.indexOf(updateItem);
            newArr[index] = {topicName, value};
            setLine({...line, topics: newArr});
        }
    }


    function setSpecialTopicValue(value: string, topicName: string) {
        console.log("Special topics ", topicName, "----", value)
        let updateItem = line.specialComponentTopics.find(keyValue => keyValue.topicName === topicName);
        if (updateItem) {
            console.log("updating")
            let index = line.specialComponentTopics.indexOf(updateItem);
            line.specialComponentTopics[index] = {topicName, value};
            setLine(line);
        } else {
            console.log("pushing")
            line.specialComponentTopics.push({topicName, value});
        }
        console.log(line.specialComponentTopics)
    }


    function setOpenStateSpecialTopic(topicName: string) {
        let updateItem = openSpecialTopics.find(state => state.topicName === topicName);
        if (updateItem) {
            let newArr = [...openSpecialTopics]
            let index = newArr.indexOf(updateItem);
            let realValue = newArr.find(value => value.topicName === topicName)?.open;
            newArr[index] = {topicName, open: !realValue};
            setOpenSpecialTopics(newArr);
        } else {
            setOpenSpecialTopics([...openSpecialTopics, ...[{topicName, open: true}]]);
        }
    }


    function renderWrapper(index: number) {
        if (index % 2 === 0) {
            return (
                <div className="server__table-sub-tbody--td server__table-sub-tbody--td" key={index}>
                    <div className="group" key={line.topics[index].topicName}>
                        <div className="group__title">{line.topics[index].topicName}</div>
                        <input type="text" value={line.topics[index].value}
                               title={line.topics[index].topicName}
                               placeholder={line.topics[index].topicName}
                               onChange={(event) => setTopicValue(event.target.value, line.topics[index].topicName)}/>
                    </div>
                    {line.topics.length > index + 1 &&
                        <div className="group" key={line.topics[index + 1].topicName}>
                            <div className="group__title">{line.topics[index + 1].topicName}</div>
                            <input type="text"
                                   value={(line.topics[index + 1].value)}
                                   title={line.topics[index + 1].topicName}
                                   placeholder={line.topics[index + 1].topicName}
                                   onChange={(event) => setTopicValue(event.target.value, line.topics[index + 1].topicName)}/>
                        </div>
                    }
                </div>
            )
        }
        return;
    }


    function selectedRoom(): RoomDTO | undefined {
        return roomStore.roomList.find(room => room.id === line.roomID);
    }

    function saveLine(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setSend(false);
        console.log("Updating line", line);
        roomStore.updateCommandData(line.id, line)
            .then((result) => setSend(true))
            .catch((e) => {
                console.log(e);
                setSend(true);
            })
    }

    return (
        <div className="server__table-sub-tbody--tr d-flex flex-wrap mb8">
            <div className="server__table-sub-tbody--td server__table-sub-tbody--title">
                {line.abbreviation}
            </div>
            <div className="server__table-sub-tbody--td server__table-sub-tbody--name">
                <div className="group">
                    <input type="text" placeholder="Название" value={line.name ? line.name : ""}
                           onChange={(event) => setValue(event.target.value, "name")}/>
                </div>
            </div>
            <div className="server__table-sub-tbody--td">
                <div className={"selectWrapper" + (roomSelector ? " is-active" : "")}
                     onClick={() => setRoomSelector(!roomSelector)}>
                    <div className="selectCustom h49 js-selectCustom">
                        <div className="selectCustom-trigger">{selectedRoom()?.name}</div>
                        {roomSelector &&
                            <div className="selectCustom-options">
                                <div key={"EMPTY_ROOM_" + "line" + line.id} className="selectCustom-option"
                                     data-value={"EMPTY_ROOM"}
                                     onClick={() => setValue(null, "roomID")}></div>
                                {roomStore.roomList.map((room) => {
                                    return <div key={room.id + "line" + line.id} className="selectCustom-option"
                                                data-value={room.id}
                                                onClick={() => setValue(room.id, "roomID")}>{room.name} </div>
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="server__table-sub-tbody--td">
                <div className="group">
                    <input type="text" value={line.order ? line.order : ""}
                           onChange={(event) => setValue(event.target.value, "order")}
                           title="Номер по порядку"/>
                </div>
            </div>
            {line.specialComponentTopics && line.specialComponentTopics.map((specialTopic) => {
                if (specialTopic.topicName === "ga1mode" || specialTopic.topicName === "ga2mode" || specialTopic.topicName === "ga3mode") {
                    return (
                        <div className="server__table-sub-tbody--td"
                             key={specialTopic.topicName + "_line_" + line.id}>
                            <div
                                className={"selectWrapper" + (openSpecialTopics.find(value => value.topicName === specialTopic.topicName)?.open ? " is-active" : "")}
                                onClick={() => setOpenStateSpecialTopic(specialTopic.topicName)}>
                                <div className="selectCustom h49 js-selectCustom">
                                    <div
                                        className="selectCustom-trigger">{buttonModes.find(value => value.id === specialTopic.value)?.value}</div>
                                    <div className="selectCustom-options">
                                        {buttonModes.map((buttonValue) => {
                                            return <div
                                                key={specialTopic.topicName + "_buttons_" + buttonValue.id + "_line_" + line.id}
                                                className="selectCustom-option"
                                                data-value={buttonValue.id}
                                                onClick={() => setSpecialTopicValue(buttonValue.id, specialTopic.topicName)}>{buttonValue.value} </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                } else if (specialTopic.topicName === "TypeTH") {
                    return (
                        <div className="server__table-sub-tbody--td"
                             key={specialTopic.topicName + "_line_" + line.id}>
                            <div
                                className={"selectWrapper" + (openSpecialTopics.find(value => value.topicName === specialTopic.topicName)?.open ? " is-active" : "")}
                                onClick={() => setOpenStateSpecialTopic(specialTopic.topicName)}>
                                <div className="selectCustom h49 js-selectCustom">
                                    <div
                                        className="selectCustom-trigger">{typeTH.find(value => value.id === specialTopic.value)?.value}</div>
                                    <div className="selectCustom-options">
                                        {typeTH.map((typeValue) => {
                                            return <div
                                                key={specialTopic.topicName + "_typeth_" + typeValue.id + "_line_" + line.id}
                                                className="selectCustom-option"
                                                data-value={typeValue.id}
                                                onClick={() => setSpecialTopicValue(typeValue.id, specialTopic.topicName)}>{typeValue.value} </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                } else if (specialTopic.topicName === "TypeAC") {
                    return (
                        <div className="server__table-sub-tbody--td"
                             key={specialTopic.topicName + "_line_" + line.id}>
                            <div
                                className={"selectWrapper" + (openSpecialTopics.find(value => value.topicName === specialTopic.topicName)?.open ? " is-active" : "")}
                                onClick={() => setOpenStateSpecialTopic(specialTopic.topicName)}>
                                <div className="selectCustom h49 js-selectCustom">
                                    <div
                                        className="selectCustom-trigger">{typeAC.find(value => value.id === specialTopic.value)?.value}</div>
                                    <div className="selectCustom-options">
                                        {typeAC.map((typeValue) => {
                                            return <div
                                                key={specialTopic.topicName + "_typeac_" + typeValue.id + "_line_" + line.id}
                                                className="selectCustom-option"
                                                data-value={typeValue.id}
                                                onClick={() => setSpecialTopicValue(typeValue.id, specialTopic.topicName)}>{typeValue.value} </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            })}
            <>
                {!props.multiline && line.topics.map((topic) => {
                    return (
                        <div className="server__table-sub-tbody--td" key={topic.topicName}>
                            <div className="group">
                                <input type="text" value={topic.value} title={topic.topicName}
                                       placeholder={topic.topicName}
                                       onChange={(event) => setTopicValue(event.target.value, topic.topicName)}/>
                            </div>
                        </div>
                    )
                })
                }

                {props.multiline && line.topics.map((row, index) => {
                    return (
                        renderWrapper(index)
                    );
                })
                }
            </>

            <div className="server__table-sub-tbody--action">
                <button onClick={(event) => saveLine(event)} disabled={!send}
                        className="server__forms-control d-flex align-items-center justify-content-center flex-wrap light-blue-border radius10">
                    <i className="icon-save"></i></button>
            </div>
        </div>
    )
}

export default LineComponent;