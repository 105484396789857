import axios from "axios";
import {
    AvailableColorsDTO,
    ClientDeviceDTO,
    ComponentGroupDTO,
    ComponentLineDTO,
    DeviceInfoDTO, FirmwareRequestDTO, KeyMultuplieValue,
    LoginDetails,
    LoginResponse,
    RefreshRequest,
    RegistrationDTO,
    RoomDTO, TimerRequestDTO, TimerUpdateDTO
} from "./types";
import {SessionStore} from "../components/store/SessionStore";

class HttpService {

    instance
    sessionStore: SessionStore = new SessionStore();

    constructor() {
        this.instance = axios.create({
            baseURL: 'https://rexmy.com.ua/api',
            // baseURL: 'http://localhost:8080/api',
            timeout: 2000,
        });
        this.instance.interceptors.request.use(config => {
            // if (config.url !== "/auth/register" && config.url !== "/auth/refresh") {
            let readToken = this.sessionStore.readToken();
            if (readToken !== undefined && readToken) {
                // @ts-ignore
                config.headers['Authorization'] = `Bearer ${readToken.accessToken}`;
            }
            // }
            return config;
        }, error => {
            return Promise.reject(error);
        });

        // Response interceptor for API calls
        this.instance.interceptors.response.use((response) => {
            return response;
        }, async error => {
            // @ts-ignore
            const originalRequest = error.config;
            console.log("Resend request")
            if (error.response.status === 403 && !originalRequest._retry) {
                console.log("Inside Request")
                let readToken = this.sessionStore.readToken();
                if (readToken !== undefined && readToken) {
                    console.log("Inside sending")
                    originalRequest._retry = true;
                    const accessToken: LoginResponse = await this.refreshToken({refreshToken: readToken.refresh});
                    if (accessToken.jwtToken !== undefined && accessToken.jwtToken !== null && accessToken.jwtToken.length > 0) {
                        this.sessionStore.writeToken(accessToken);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken.jwtToken;
                        return this.instance(originalRequest);
                    }
                }
            }
            return Promise.reject(error);
        });
    }

    async login(request: LoginDetails): Promise<LoginResponse | null> {
        return await this.instance
            .post("/auth/authorise", request)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.debug(error);
                return null;
            });
    }

    async refreshToken(request: RefreshRequest): Promise<LoginResponse> {
        return await this.instance
            .post("/auth/refresh", request)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                console.debug(error);
                return {
                    expireDate: new Date(),
                    jwtToken: "",
                    refreshTokenExpireDate: new Date(),
                    refreshToken: ""
                };
            });
    }

    async register(request: RegistrationDTO): Promise<boolean> {
        return await this.instance
            .post("/auth/register", request)
            .then(function (response) {
                return true;
            })
            .catch(function (error) {
                console.debug(error);
                return false;
            });
    }

    async getAllRoom(): Promise<RoomDTO[]> {
        return await this.instance
            .get("/rooms/all")
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.debug(error);
                return [];
            });
    }

    async addNewRoom(request: RoomDTO) {
        return await this.instance
            .post("/rooms/", request)
            .then(function (response) {
                return response.data;
            })
    }

    async deleteRoom(request: number) {
        return await this.instance
            .delete("/rooms/" + request);
    }

    async getAllDeviceInfoList(): Promise<DeviceInfoDTO[]> {
        return await this.instance
            .get("/device/info")
            .then(function (response) {
                return response.data;
            });
    }

    async getAllClientDevice(): Promise<ClientDeviceDTO[]> {
        return await this.instance
            .get("/cdc/")
            .then(function (response) {
                return response.data;
            });
    }

    async addNeDeviceToClient(addNewRequest: ClientDeviceDTO): Promise<ClientDeviceDTO> {
        return await this.instance
            .post("/cdc/", addNewRequest)
            .then(function (response) {
                return response.data;
            });
    }

    async updateDevice(id: number, addNewRequest: ClientDeviceDTO): Promise<ClientDeviceDTO> {
        return await this.instance
            .put("/cdc/" + id, addNewRequest)
            .then(function (response) {
                return response.data;
            });
    }

    async getDeviceValues(id: number): Promise<ComponentGroupDTO[]> {
        return await this.instance
            .get("/cdc/device/" + id)
            .then(function (response) {
                return response.data;
            });
    }

    async deleteClientDevice(id: number): Promise<void> {
        return await this.instance
            .delete("/cdc/" + id)
            .then(function (response) {
                return response.data;
            });
    }

    async saveCommandValues(lineData: ComponentLineDTO, id: number): Promise<ComponentLineDTO> {
        console.log(lineData)
        return await this.instance
            .post("/cdc/device/command/" + id, lineData)
            .then(function (response) {
                return response.data;
            });
    }

    async saveManagementValues(lineData: ComponentLineDTO, id: number): Promise<ComponentLineDTO> {
        return await this.instance
            .post("/cdc/device/management/" + id, lineData)
            .then(function (response) {
                return response.data;
            });
    }

    async saveManagementValuesJson(lineData: KeyMultuplieValue, id: number): Promise<ComponentLineDTO> {
        return await this.instance
            .post("/cdc/device/management/multi/" + id, lineData)
            .then(function (response) {
                return response.data;
            });
    }

    async loadRoomDevices(id: number): Promise<ComponentLineDTO[]> {
        return await this.instance
            .get("/cdc/room/" + id)
            .then(function (response) {
                return response.data;
            });
    }

    async loadRoomChanges(id: number): Promise<ComponentLineDTO[]> {
        return await this.instance
            .get("/cdc/room/update/" + id)
            .then(function (response) {
                return response.data;
            });
    }

    async updateTimer(updateRequest: TimerUpdateDTO) {
        return await this.instance
            .post("/timer/update", updateRequest)
            .then(function (response) {
                return response.data;
            });
    }

    async requestTimer(request: TimerRequestDTO): Promise<{ [name: string]: string }> {
        return await this.instance
            .post("/timer/", request)
            .then(function (response) {
                return response.data;
            });
    }

    async sendFirmwareUpdate(request: FirmwareRequestDTO) {
        return await this.instance
            .put("/firmware", request)
            .then(function (response) {
                return response.data;
            });
    }

    async getAvailableColors(): Promise<AvailableColorsDTO[]> {
        return await this.instance
            .get("/colors")
            .then(function (response) {
                return response.data;
            });
    }

    async saveAvailableColor(request: AvailableColorsDTO): Promise<AvailableColorsDTO> {
        return await this.instance
            .post("/colors", request)
            .then(function (response) {
                return response.data;
            });
    }
}

export default new HttpService();